import Navbar from 'react-bootstrap/Navbar';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
function Header()
{
    return(
        
        <>
        <Navbar style={{ ...{backgroundColor:'#007377'},...{borderRadius:'0px'},...{padding: '1rem 1rem'} }}  variant="dark">
        <div className="d-lg-block d-md-block d-sm-block d-xl-none text-center" >
                        <img src={logo} alt="logo" style={{width:'20%'}} className="img-responsive mr-auto ml-auto"/>
                    </div>
      </Navbar>
  
        </>
    )
}

export default Header;