import React from 'react';
import Skeleton from 'react-loading-skeleton';
import * as Bt from 'react-bootstrap/';
const ReactSkeleton = () =>{
    return(
        <>
        {Array(3).fill().map( (item,index) =>(
        <div key={index} style={{ width: '25%' }} className="p-2 widthmn flex-fill text-center ml-5" >
          
        <Skeleton style={{height:'100%',width:'100%',lineHeight:"7",backgroundColor:'#eeeeee96',backgroundImage: 'linear-gradient( 90deg,#eeeeee59,#eeeeee59,#eeeeee59 )'}} />
 
        </div>) 
        )}
        
        </>
    )
}

const IngredientSkeleteon = () =>{
    return(
        <>
        {Array(3).fill().map((item,index) => (
            
            <Bt.Col key={index} className={((index+1) % 2 === 0 ? 'p-0 bt2' : ((index+1) % 3 ===    0 ? 'pl-0 bt3' : 'pr-0 bt1'))}  md={4}>
                     <Bt.Card.Header style={{borderRadius: '0', color: 'white',backgroundColor: ((item) % 2 === 0 ? '#f8a02b' : '#007377')}}>
                         
                         <Skeleton style={{height:'100%',width:'100%',lineHeight:"2",backgroundColor:'#eeeeee96',backgroundImage: 'linear-gradient( 90deg,#eeeeee59,#eeeeee59,#eeeeee59 )'}} />
                    </Bt.Card.Header>
                     <Bt.Card.Body style={{ paddingLeft: '30px' }}>
               
                    <Skeleton style={{height:'100%',width:'100%',lineHeight:"7",backgroundColor:'#eeeeee96',backgroundImage: 'linear-gradient( 90deg,#eeeeee59,#eeeeee59,#eeeeee59 )'}} /><br />
              
            
            </Bt.Card.Body>  
                </Bt.Col>
        ))}
        </>
    )
}

const RegSkeleton = () =>{
    return (
        <>
            {Array(4).fill().map((item,index) => (
                <Bt.Col md={3} key={index}>
                    
                    <Skeleton style={{height:'100%',width:'100%',lineHeight:"7",backgroundColor:'#eeeeee96',backgroundImage: 'linear-gradient( 90deg,#eeeeee59,#eeeeee59,#eeeeee59 )'}} />
                   
                </Bt.Col>
            ))}
        </>  
    )
}

export { ReactSkeleton ,IngredientSkeleteon , RegSkeleton };