import React, { Component } from 'react';
import * as Bt from 'react-bootstrap/';
import logo from './logo.svg';
import './App.css';
import banner from './pizzabanner.jpg';
import pizza from './pizza.png';
import axios from 'axios';
import { ReactSkeleton, IngredientSkeleteon, RegSkeleton } from './skeleton';
class Screen extends Component {

    SERVER_PATH = "https://www.rio.codeium.no/";

    constructor() {
        super();
        this.state = {
            loading: true,
            pizzaQty: 1,
            mineralQty: [],
            easyBase: [],
            easyIngred: [],
            regularProducts: [],
            regLoad: true,
            cart: [],
            regularCartTotal: null
        }
    }

    componentDidMount() {
        const base = () => this.getData();

        const ingred = () => this.getIngred();

        const regular = () => this.getregular();

        base();
        ingred();
        regular();

    }


    /* Update Cart */
    updateCart = () => {
        let selectedSouces = null;
        let ingredients = null;
        let selectedMinerals = null;
        let selectedBase = null;
        let regular = null;


        /* Bases */

        let Bases = this.state.easyBase

        selectedBase = Bases.filter(item => item.quantity > 0)
        if (selectedBase.length > 0) {
            /**Bases Amount */
            var baseAmount = 0;
            var baseQty = 0;
            for (let base_index = 0; base_index < selectedBase.length; base_index++) {

                baseAmount = baseAmount + parseFloat(selectedBase[base_index].price._price);
                baseQty = baseQty + parseFloat(selectedBase[base_index].quantity);
            }
            baseAmount = baseAmount * baseQty;
            /* Ingredients */
            let ingredient_categories = this.state.easyIngred;

            let ingredient_object = {};
            var totalIngreds = 0
            for (let index = 0; index < ingredient_categories.length; index++) {
                var obj = ingredient_categories[index];
                var selected_ing = obj.products.posts.filter(item => item.is_selected);
                var total_ingredients = 0;

                for (let _index = 0; _index < selected_ing.length; _index++) {
                    var _item = selected_ing[_index];
                    total_ingredients = total_ingredients + parseFloat(_item.price._price[0]);
                    totalIngreds = totalIngreds + parseFloat(_item.price._price[0]);
                }


                ingredient_object[obj.slug] = {
                    title: obj.name,
                    products: selected_ing,
                    total_price: total_ingredients
                }

            }
            totalIngreds = totalIngreds * baseQty;
            /* Ingredients Total */

            ingredients = ingredient_object;

        } else {
            totalIngreds = 0;
            baseAmount = 0;
        }


        if (this.state.regularProducts.length > 0) {
            /* sauces */
            let regSauce = this.state.regularProducts[1];

            regSauce = (regSauce.data.posts !== undefined) ? regSauce.data.posts : [];

            /* Selected Sauces */
            selectedSouces = regSauce.filter(item => item.quantity > 0);

            /* Minerals */

            let regMinerals = this.state.regularProducts[0];

            regMinerals = (regMinerals.data.posts !== undefined) ? regMinerals.data.posts : [];

            selectedMinerals = regMinerals.filter(item => item.quantity > 0);
            regular = selectedMinerals.concat(selectedSouces);
        }



        let regular_total = this.regularTotal(regular);

        this.setState({
            cart: {
                easyBaseCart: selectedBase,
                regular_products: regular,
                easyIngredCart: ingredients,
                regularProducts_total: regular_total,
                bases_total: baseAmount,
                ingredient_total: totalIngreds,
            }
        })

    }


    async getData() {
        const resp = await axios.get(this.SERVER_PATH + 'wp-json/pizzaApi/v2/bases/easy')
        this.setState({ easyBase: resp.data.posts, loading: false })

    }

    async getIngred() {

        const res = await axios.get(this.SERVER_PATH + 'wp-json/pizzaCategoriesApi/v2/ingredients')
        this.setState({ easyIngred: res.data })

    }

    async getregular() {
        let regular = ['mineral', 'sauce']
        for (let i = 0; i < regular.length; i++) {
            const response = await axios.get(this.SERVER_PATH + 'wp-json/pizzaApi/v2/products/' + regular[i])
            this.state.regularProducts.push(response)

        }
        this.setState({ regLoad: false })

    }

    IngredCart = (easyIngredients) => {
            let all_items = [];


            for (const key of Object.keys(easyIngredients)) {

                /* Total */
                all_items.push(
                    easyIngredients[key].products.map((item, child_index) => {


                        return ( < li key = { child_index } > { item.post_title + ' @' + item.price._price } < /li>)
                        }))


                }

                return all_items;
            }

            easyBases = () => {
                const Base = this.state.cart.easyBaseCart
                const easy_ingreds = this.state.cart.easyIngredCart
                const ing_tot = (this.state.cart.ingredient_total !== undefined ? this.state.cart.ingredient_total : 0)
                return (
                    Base.map((item, index) => {

                        return ( < tr key = { index } >
                            <
                            td >
                            <
                            span onClick = {
                                (e) => this.cartUpdate_option('minus', index, 'base')
                            }
                            style = {
                                { color: 'rgb(0 115 119)' }
                            }
                            className = "minus addspan " > - < /span> <
                            p className = "count addinput" > { item.quantity } < /p> <
                            span onClick = {
                                (e) => this.cartUpdate_option('plus', index, 'base')
                            }
                            style = {
                                { color: 'rgb(0 115 119)' }
                            }
                            className = "plus " > + < /span></td >
                            <
                            td style = {
                                { fontSize: '21px', color: '#007377', textAlign: 'center' }
                            } > { item.post_title } < /td> <
                            td >
                            <
                            ul style = {
                                { listStyle: "none", fontSize: '15px', color: '#007377', textAlign: 'left' }
                            } > { Object.keys(this.state.cart).length > 0 ? this.IngredCart(easy_ingreds) : '' } { 'Total : ' + ing_tot } <
                            /ul> < /
                            td > <
                            td style = {
                                { fontSize: '21px', color: '#007377', textAlign: 'center' }
                            } > { this.state.cart_total } { item.price._price } < /td> <
                            td style = {
                                { fontSize: '21px', color: '#007377', textAlign: 'center' }
                            } > { item.price._price + ' x ' + item.quantity + ' = ' + item.price._price * item.quantity } < /td> < /
                            tr > )
                    }))
            }

            handleClick = (a, index, ID) => {
                let regSauce = this.state.regularProducts;

                if (a === 'plus') {
                    regSauce[1].data.posts[index].quantity++;
                } else {
                    if (regSauce[1].data.posts[index].quantity !== 0) {

                        regSauce[1].data.posts[index].quantity--;
                    }
                }

                this.setState({
                    regularProducts: regSauce
                });
                this.updateCart();

            }

            handleClickMin = (a, index) => {
                let data = this.state.regularProducts;

                if (a === 'plus') {
                    data[0].data.posts[index].quantity++;
                } else {
                    if (data[0].data.posts[index].quantity !== 0)
                        data[0].data.posts[index].quantity--;
                }
                this.updateCart();
                this.setState({
                    regularProducts: data
                });

            }

            cartUpdate_option = (a, index, r) => {
                let cart_items_regular = this.state.cart.regular_products;
                let cart_items_bases = this.state.cart.easyBaseCart;
                let easy_ingred_cart = (this.state.cart.easyIngredCart !== undefined ? this.state.cart.easyIngredCart : [])
                var regular_total = (this.state.cart.regularProducts_total !== undefined ? this.state.cart.regularProducts_total : 0)
                let Ing_amt = (this.state.cart.ingredient_total !== undefined ? this.state.cart.ingredient_total : 0)
                let base_amt = (this.state.cart.bases_total !== undefined ? this.state.cart.bases_total : 0)

                if (r === 'regular') {

                    if (a === 'plus') {
                        cart_items_regular[index].quantity++;
                    } else {
                        if (cart_items_regular[index].quantity !== 0) {
                            cart_items_regular[index].quantity--;
                        }
                    }
                    cart_items_regular = cart_items_regular.filter(item => item.quantity > 0)
                    regular_total = this.regularTotal(cart_items_regular);
                } else {

                    if (a === 'plus') {
                        cart_items_bases[index].quantity++;
                    } else {
                        if (cart_items_bases[index].quantity !== 0) {
                            cart_items_bases[index].quantity--;
                        }
                    }

                    cart_items_bases = cart_items_bases.filter(item => item.quantity > 0)

                    if (cart_items_bases.length > 0) {
                        /* Ingredients Amount of all */
                        var new_ingredientAmt = 0;

                        for (let key in easy_ingred_cart) {

                            new_ingredientAmt = new_ingredientAmt + parseFloat(easy_ingred_cart[key].total_price);
                        }
                        /* Ingredients and Bases Amount of all */
                        var bases_amount = 0
                        var totalIngred_amount = 0
                        for (let __index = 0; __index < cart_items_bases.length; __index++) {

                            totalIngred_amount = (new_ingredientAmt * cart_items_bases[__index].quantity)

                            bases_amount = (parseFloat(cart_items_bases[__index].quantity) * parseFloat(cart_items_bases[__index].price._price));
                        }

                        Ing_amt = totalIngred_amount;
                        base_amt = bases_amount;

                    } else {
                        Ing_amt = 0;
                        base_amt = 0;
                    }

                }


                this.setState({
                    cart: {
                        regular_products: cart_items_regular,
                        regularProducts_total: regular_total,
                        easyBaseCart: cart_items_bases,
                        easyIngredCart: easy_ingred_cart,
                        bases_total: base_amt,
                        ingredient_total: Ing_amt,
                    }
                })


            }

            regularTotal = (cart_items) => {
                let regularCart_total = null;
                let total = cart_items.map(item => item.quantity * item.price._price)
                regularCart_total = total.reduce(function(a, b) {
                    return a + b;
                }, 0);
                return (
                    regularCart_total
                )
            }


            regularCart = () => {

                const regularCart_product = this.state.cart.regular_products

                return (
                    regularCart_product.map((item, index) => {
                        return ( < tr key = { index } >
                            <
                            td > < span onClick = {
                                (e) => this.cartUpdate_option('minus', index, 'regular')
                            }
                            style = {
                                { color: 'rgb(0 115 119)' }
                            }
                            className = "minus addspan " > - < /span> <
                            p className = "count addinput" > { item.quantity } < /p> <
                            span onClick = {
                                (e) => this.cartUpdate_option('plus', index, 'regular')
                            }
                            style = {
                                { color: 'rgb(0 115 119)' }
                            }
                            className = "plus " > + < /span></td >
                            <
                            td style = {
                                { fontSize: '21px', color: '#007377', textAlign: 'center' }
                            } > { item.post_title } < /td> <
                            td style = {
                                { fontSize: '21px', color: '#007377', textAlign: 'center' }
                            } > { item.price._price } < /td> <
                            td style = {
                                { fontSize: '21px', color: '#007377', textAlign: 'center' }
                            } > { item.price._price + ' x ' + item.quantity + ' = ' + item.price._price * item.quantity } < /td> < /
                            tr >
                        )
                    })
                )
            }

            checkItems = () => {
                const sauce = this.state.regularProducts[1].data.posts

                return (
                    sauce.map((item, index) => {
                        if (item.quantity === undefined) {
                            item.quantity = 0;
                        }
                        return ( <
                            Bt.Col md = { 3 } >
                            <
                            Bt.Card style = {
                                { border: 'none' }
                            } >
                            <
                            Bt.Card.Img style = {
                                { marginLeft: 'auto', marginRight: 'auto', width: '50%' }
                            }
                            variant = "top"
                            src = { item.featured_image }
                            /> <
                            Bt.Card.Body style = {
                                { textAlign: 'center' }
                            } >
                            <
                            Bt.Card.Title > { item.post_title } < /Bt.Card.Title>

                            <
                            div className = "qty adddiv" >
                            <
                            span onClick = {
                                (e) => this.handleClick('minus', index)
                            }
                            className = "minus addspan bg-dark" > - < /span> <
                            p className = "count addinput" > { item.quantity } < /p> <
                            span onClick = {
                                (e) => this.handleClick('plus', index)
                            }
                            className = "plus bg-dark" > + < /span> < /
                            div >


                            <
                            /Bt.Card.Body> < /
                            Bt.Card > <
                            /Bt.Col>
                        )
                    })

                )
            }

            checkMinerals = () => {
                let Minerals = this.state.regularProducts[0].data.posts

                return (
                    Minerals.map((item, index) => {
                        if (item.quantity === undefined) {
                            item.quantity = 0;
                        }
                        return ( <
                            Bt.Col md = { 3 } >
                            <
                            Bt.Card style = {
                                { border: 'none' }
                            } >
                            <
                            Bt.Card.Img style = {
                                { marginLeft: 'auto', marginRight: 'auto', width: '40%' }
                            }
                            variant = "top"
                            src = { item.featured_image }
                            /> <
                            Bt.Card.Body style = {
                                { textAlign: 'center' }
                            } >
                            <
                            Bt.Card.Title > { item.post_title } < /Bt.Card.Title>

                            <
                            div className = "qty mt-5 adddiv" >
                            <
                            span onClick = {
                                (e) => this.handleClickMin('minus', index)
                            }
                            className = "minus addspan bg-dark" > - < /span> <
                            p className = "count addinput" > { item.quantity } < /p> <
                            span onClick = {
                                (e) => this.handleClickMin('plus', index)
                            }
                            className = "plus bg-dark" > + < /span> < /
                            div >

                            <
                            /Bt.Card.Body> < /
                            Bt.Card > <
                            /Bt.Col>
                        )
                    })
                )
            }

            render() {

                const easy_base = this.state.easyBase

                const plainPizza = easy_base.map((data, index) => {
                        if (data.quantity === undefined) {
                            data.quantity = 0;
                        }
                        return ( < div key = { index }
                            onClick = {
                                () => {
                                    if (data.quantity === 0) {
                                        data.quantity = 1;
                                    } else {
                                        data.quantity = 0;

                                    }

                                    this.setState({
                                        easyBase: easy_base
                                    })
                                    this.updateCart();
                                }
                            }
                            style = {
                                { width: '25%' }
                            }
                            className = { "p-2 widthmn flex-fill text-center ml-4 " + (data.quantity > 0 ? 'border' : '') } >

                            <
                            img alt = "pizza"
                            style = {
                                { width: '40%' }
                            }
                            src = { data.featured_image }
                            className = "img-responsive" / >

                            <
                            div style = {
                                { display: 'inline-grid' }
                            } >
                            <
                            h4 > { data.post_title } < /h4> <
                            p > fro { data.price._price } < /p> < /
                            div > <
                            /div>)
                        }
                    )


                    const diffPizza = < div className = "d-flex flex-wrap justify-content-start flxmin bg-white border-bottom"
                    style = {
                        { position: 'relative' }
                    } >
                    <
                    div className = "p-2"
                    style = {
                        { height: '100%', width: '35px', position: 'absolute', backgroundColor: '#007377' }
                    } >
                    <
                    div className = "strip-container" >
                    <
                    p style = {
                        { marginLeft: '12px' }
                    } > < span > MED < /span> <span>KJOTT</span > < /p> < /
                    div > <
                    /div>

                    {
                        Array(6).fill().map(index => < div className = "p-2 widthmn w-25 flex-fill text-center ml-4" >

                            <
                            img alt = "pizza"
                            style = {
                                { width: '40%' }
                            }
                            src = { pizza }
                            className = "img-responsive" / >

                            <
                            div style = {
                                { display: 'inline-grid' }
                            } >
                            <
                            h4 > Plain < /h4> <
                            p > fr235 < /p> < /
                            div > <
                            /div>
                        )
                    } <
                    /div>

                    const easyIngred = this.state.easyIngred;

                    const ingredients = easyIngred.map((data, parent_index) => {

                            return ( <
                                Bt.Col key = { parent_index }
                                className = {
                                    ((parent_index + 1) % 2 === 0 ? 'p-0 bt2' : ((parent_index + 1) % 3 === 0 ? 'pl-0 bt3' : 'pr-0 bt1'))
                                }
                                md = { 4 } >
                                <
                                Bt.Card.Header style = {
                                    { borderRadius: '0', color: 'white', backgroundColor: ((parent_index + 1) % 2 === 0 ? '#f8a02b' : '#007377') }
                                } > { data.name } < /Bt.Card.Header> <
                                Bt.Card.Body style = {
                                    { paddingLeft: '30px' }
                                } > {
                                    data.products.posts.map((item, index) => {

                                            if (item.is_selected === undefined) {
                                                item.is_selected = false;
                                            }

                                            return ( < div key = { index } > < label className = "checkbox" >
                                                <
                                                input type = "checkbox"
                                                checked = { item.is_selected }
                                                onChange = {
                                                    () => {
                                                        if (item.is_selected) {
                                                            item.is_selected = false;
                                                        } else {
                                                            item.is_selected = true;
                                                        }

                                                        this.setState({
                                                            easyIngred: easyIngred
                                                        })

                                                        this.updateCart();
                                                    }
                                                }
                                                />{' ' + item.post_title} < /
                                                label > < br / >
                                                <
                                                /div>)
                                            }
                                        )
                                    }

                                    <
                                    /Bt.Card.Body> < /
                                    Bt.Col >
                                )
                            })



                        return ( <
                                >
                                <
                                div className = "d-lg-none d-md-none d-sm-none d-xl-block d-none p-2"
                                style = {
                                    { position: 'fixed' }
                                } >
                                <
                                img src = { logo }
                                style = {
                                    { width: '100%' }
                                }
                                alt = "logo"
                                className = "img-responsive text-center" / >
                                <
                                /div> <
                                Bt.Container >
                                <
                                div >
                                <
                                img alt = "banner"
                                src = { banner }
                                style = {
                                    { width: '100%', height: 'auto' }
                                }
                                className = "img-responsive" / >
                                <
                                /div>

                                <
                                div className = "d-flex flex-wrap  flxmin bg-white border-bottom"
                                style = {
                                    { position: 'relative' }
                                } >
                                <
                                div className = "p-2"
                                style = {
                                    { height: '100%', width: '35px', position: 'absolute', backgroundColor: '#007377' }
                                } >
                                <
                                div className = "strip-container" >
                                <
                                p style = {
                                    { marginLeft: '12px' }
                                } > PLAIN < /p> < /
                                div > <
                                /div> { this.state.loading ? < ReactSkeleton / >: plainPizza
                            } <
                            /div>

                        { diffPizza } <
                        Bt.Card style = {
                            { borderRadius: '0px' }
                        }
                        className = "border-0" >
                        <
                        Bt.Row > { easyIngred.length === 0 ? < IngredientSkeleteon / > : ingredients } <
                        /Bt.Row> < /
                        Bt.Card > <
                        Bt.Card style = {
                            { borderRadius: '0px' }
                        }
                        className = "border-0" >
                        <
                        Bt.Card.Header style = {
                            { backgroundColor: '#007377', borderRadius: '0 !important', color: 'white', textAlign: 'center' }
                        } > TilBEHOR < /Bt.Card.Header> <
                        Bt.Card.Body style = {
                            { paddingLeft: '30px' }
                        } >
                        <
                        Bt.Row > { this.state.regLoad ? < RegSkeleton / > : this.checkItems() } <
                        /Bt.Row> < /
                        Bt.Card.Body > <
                        /Bt.Card> <
                        Bt.Card >
                        <
                        Bt.Card.Header style = {
                            { backgroundColor: '#f8a02b', borderRadius: '0 !important', color: 'white', textAlign: 'center' }
                        } > MINERALVANN 1, 5 L < /Bt.Card.Header> <
                        Bt.Card.Body style = {
                            { paddingLeft: '30px' }
                        } >
                        <
                        Bt.Row > { this.state.regLoad ? < RegSkeleton / > : this.checkMinerals() } <
                        /Bt.Row> < /
                        Bt.Card.Body > <
                        /Bt.Card>

                        <
                        Bt.Col style = {
                            { backgroundColor: '#007377', color: 'white', textAlign: 'center', height: '48px' }
                        }
                        md = { 12 } >
                        <
                        div style = {
                            { paddingTop: '11px' }
                        } > HANDLEKURV < /div> < /
                        Bt.Col > <
                        Bt.Col md = { 12 }
                        className = "bg-white text-center p-5" >


                        <
                        div className = "qty  adddiv " >
                        <
                        p style = {
                            { paddingLeft: '115px', fontSize: '25px', fontWeight: '500', color: '#f8a02b', marginBottom: '0px' }
                        } > Plain Pizza < /p> <
                        Bt.Table responsive >
                        <
                        tbody > { Object.keys(this.state.cart).length > 0 ? this.easyBases() : '' } <
                        /tbody> < /
                        Bt.Table > {
                            /* <span onClick={(e) => this.handleClickMin('minus', e)} style={{ color: 'rgb(0 115 119)' }} className="minus addspan ">-</span>
                                                        <p className="count addinput"  >1</p>
                                                        <span onClick={(e) => this.handleClickMin('plus', e)} style={{ color: 'rgb(0 115 119)' }} className="plus ">+</span>

                                                        <span style={{ paddingLeft: '19px', fontSize: '25px', fontWeight: '500', color: '#f8a02b' }}>Plain Pizza </span>
                                                        <ul style={{ listStyle: "none", paddingLeft: '77px', fontSize: '21px', marginTop: '-10px', color: '#007377' }}>
                                                            <li>ddshds</li>
                                                            <li>ddshds</li>
                                                            <li>ddshds</li>
                                                        </ul> */
                        } <
                        /div>

                        <
                        div className = "qty  adddiv " >
                        <
                        p style = {
                            { paddingLeft: '115px', fontSize: '25px', fontWeight: '500', color: '#f8a02b', marginBottom: '0px' }
                        } > TilBEHOR < /p> <
                        Bt.Table responsive >
                        <
                        tbody > { Object.keys(this.state.cart).length > 0 ? this.regularCart() : '' } <
                        /tbody> < /
                        Bt.Table > <
                        /div>

                        <
                        div style = {
                            { paddingLeft: '85px', color: '#007377' }
                        } > < span style = {
                            { fontSize: '25px', fontWeight: '500' }
                        } > Total < /span><br / > { Object.keys(this.state.cart).length > 0 ? (this.state.cart.regularProducts_total + this.state.cart.bases_total + this.state.cart.ingredient_total) : '' } < br / >
                        <
                        Bt.Button style = {
                            { backgroundColor: 'rgb(0 115 119)', border: 'none', width: '20%', marginTop: '5px', borderRadius: '0' }
                        } > BESTIL < /Bt.Button> <
                        div style = {
                            { paddingTop: '30px' }
                        } >

                        <
                        p style = {
                            { marginTop: '10px' }
                        } > Eller Vil du Karasake loge fler pizzaoer < /p> <
                        Bt.Button style = {
                            { backgroundColor: '#f8a02b', border: 'none', width: '17%', marginTop: '5px', borderRadius: '0' }
                        } > LAG ENTIL < /Bt.Button> < /
                        div > <
                        /div>




                        <
                        /Bt.Col>

                        <
                        /Bt.Container>

                        <
                        />
                    );
                }
            }
            export default Screen;